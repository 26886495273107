<template>
    <div class="aplayz_loading">
        <svg
            version="1.1"
            id="Layer_1"
            fill="#FF2D55"
            x="0px"
            y="0px"
            width="24px"
            height="30px"
            viewBox="0 0 24 30"
            style="enable-background: new 0 0 50 50"
            xml:space="preserve"
        >
            <rect x="0" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="height"
                    attributeType="XML"
                    values="10; 20; 10"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    attributeType="XML"
                    values="10; 5; 10"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
        </svg>
        <p
            class="aplayz_loading_text"
            v-if="this.$store.state.findChannel === 'Y' && preurl === '/space/updateFindChannel'"
        >
            변경된 채널로 입장 중 입니다.
        </p>
        <p
            class="aplayz_loading_text"
            v-else-if="this.$store.state.findChannel === 'Y' && preurl !== '/space/updateFindChannel'"
        >
            채널로 입장 중 입니다.
        </p>
        <p class="aplayz_loading_text" v-else-if="curupdate === 'updated'">변경된 큐레이션으로 선곡중입니다.</p>
        <p class="aplayz_loading_text" v-else>플레이리스트를 불러오는 중 입니다.</p>
    </div>
</template>
<script>
export default {
  name: 'FullLoading',
  props: {
    preurl: {
      type: String,
      required: false,
      default: ''
    },
    curupdate: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style scoped>
.aplayz_loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--black);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
}

.aplayz_loading_text {
  text-align: center;
  color: var(--whitefff);
  font-size: 16px;
  margin-bottom: 0px;
}
@media all and (max-width: 768px) {
  .aplayz_loading {
    display: flex;
  }
}
</style>
